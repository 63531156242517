

import { Vue, Component, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
// import LoanCard from "../LoanCard.vue";
import moment from "moment";
@Component({
  components: {
    ViewDocument,
    CommonDeleteModal,
  },
})
export default class TitleReports extends Vue {
  public loanId: any = null;
  public reportsFor;
  public titleInfo: any = null;
  public taskId: any = null;
  public allDocs: any = null;
  public countToSendOnServer: any = null;
  public showAddDocbutton: any = true;
  public documentName: any = null;
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;
  public step: any = 2;
  public alreadyNotified: any = true;
  public doc: any = null;
  public sessionObject: any = null;
  public docId: any = null;
  public document: any = null;
  public reOrderCount: any = null;
  public isProcessorNotifiedOnce = false;
  public appraisalCount: any = null;

  // GET LOAN DETAILS
  public async getLoanDetails() {
    try {
      this.callLoader(true, null);
      if (!this.taskId) this.$router.push({ path: "/agent-dashboard" });
      const response = await Axios.get(
        BASE_API_URL + `authentication/loanDetaillsforLoanCard/${this.loanId}`
      );
      if (response && response.status === 201) {
        this.titleInfo = response?.data?.obj || null;
        if (!this.titleInfo) this.$router.push({ path: "/agent-dashboard" });
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  //GET DOCUMENTS..
  public async returnDocs() {
    try {
      this.callLoader(true, null);
      let response;
      if (this.taskId == 6) {
        response = await Axios.get(
          BASE_API_URL + "processor/returnDocsToBeUploaded/" + this.loanId,
          { headers: authHeader() }
        );
      } else {
        response = await Axios.get(
          BASE_API_URL +
            "processor/returnDocsToBeUploadedTitleLow/" +
            this.loanId +
            "?secondAppraisalCount=null",
          { headers: authHeader() }
        );
      }
      if (response.status === 201) {
        if (this.taskId == 6) {
          this.countToSendOnServer = response.data.reOrderCount;
          this.reOrderCount = response.data.reOrderCount;
          this.appraisalCount = null;
        } else {
          this.countToSendOnServer =
            response.data.taskData.updatedTitle[0].appraisalRefCount;
          this.reOrderCount = response.data.reOrderCount;
          this.appraisalCount = response.data.appraisalCount;
        }

        this.allDocs = response.data.allDocs;

        if (this.taskId == 6) {
          this.allDocs = this.allDocs.map((element) => {
            let msg = "";
            let status = false;
            let notifyed = false;
            if (
              element.allRejectedComments &&
              element.allRejectedComments.length > 0
            ) {
              for (let i = 0; i < element.allRejectedComments.length; i++) {
                const e = element.allRejectedComments[i];
                msg += `${e.comment} ${
                  e.acceptedOrRejectedOn
                    ? moment(e.acceptedOrRejectedOn).format(
                        "MM/DD/YYYY, h:mm:ss a"
                      )
                    : ""
                } -${e.name ? e.name : ""} *** `;
              }
            }
            if (element.docFiles && element.docFiles.length > 0) {
              for (let j = 0; j < element.docFiles.length; j++) {
                const e = element.docFiles[j];
                status = e.status == "Accept" ? true : false;

                notifyed = e.files[e.files.length - 1]
                  ? e.files[e.files.length - 1].isNotified
                  : false;
              }
            }
            element["msgString"] = msg;
            let msg2 = "";

            if (
              element.allAcceptedComments &&
              element.allAcceptedComments.length > 0
            ) {
              for (let j = 0; j < element.allAcceptedComments.length; j++) {
                const e = element.allAcceptedComments[j];
                msg2 += `${
                  e.acceptedOrRejectedOn
                    ? moment(e.acceptedOrRejectedOn).format(
                        "MM/DD/YYYY, h:mm:ss a"
                      )
                    : ""
                } -${e.name ? e.name : ""} *** `;
              }
            }
            element["msgString1"] = msg2;

            element["Status"] = status;
            element["isNotified"] = notifyed;
            return element;
          });
        } else {
          this.allDocs = this.allDocs.map((element) => {
            let msg = "";
            let status = false;
            let notifyed = false;
            if (
              element.allRejectedComments &&
              element.allRejectedComments.length > 0
            ) {
              for (let i = 0; i < element.allRejectedComments.length; i++) {
                const e = element.allRejectedComments[i];
                msg += `${e.comment} ${
                  e.acceptedOrRejectedOn
                    ? moment(e.acceptedOrRejectedOn).format(
                        "MM/DD/YYYY, h:mm:ss a"
                      )
                    : ""
                } -${e.name ? e.name : ""} *** `;
              }
            }
            if (element.docFiles && element.docFiles.length > 0) {
              for (let j = 0; j < element.docFiles.length; j++) {
                const e = element.docFiles[j];
                status = e.status == "Accept" ? true : false;

                notifyed = e.files[e.files.length - 1]
                  ? e.files[e.files.length - 1].isNotified
                  : false;
              }
            }
            element["msgString"] = msg;
            let msg2 = "";

            if (
              element.allAcceptedComments &&
              element.allAcceptedComments.length > 0
            ) {
              for (let j = 0; j < element.allAcceptedComments.length; j++) {
                const e = element.allAcceptedComments[j];
                msg2 += `${
                  e.acceptedOrRejectedOn
                    ? moment(e.acceptedOrRejectedOn).format(
                        "MM/DD/YYYY, h:mm:ss a"
                      )
                    : ""
                } -${e.name ? e.name : ""} *** `;
              }
            }
            element["msgString1"] = msg2;

            element["Status"] = status;
            element["isNotified"] = notifyed;
            return element;
          });
        }

        console.log(this.allDocs);
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  //ADD MORE DOCS
  public async addSpecificDocument() {
    try {
      let res;
      if (this.taskId == 6) {
        res = await Axios.post(
          BASE_API_URL + "loanChecklist/addSpecificDocument",
          {
            loanId: this.loanId,
            name: this.documentName,
            reOrderCount: this.reOrderCount,
            appraisalRefCount: this.appraisalCount,
            taskId: this.taskId,
          },
          { headers: authHeader() }
        );
      } else {
        res = await Axios.post(
          BASE_API_URL + "processor/addUpdatedTitleLowDoc",
          {
            loanId: this.loanId,
            name: this.documentName,
            appraisalRefCount: this.reOrderCount,
            secondAppraisalCount: null,
            reOrderCount: this.reOrderCount,
          },
          { headers: authHeader() }
        );
      }

      if (res.status == 201) {
        this.$snotify.success("Document has been Added.");
        this.$modal.hide("addDocument");
        await this.returnDocs();
        this.documentName = null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // VIEW DOCUMENT
  public async viewDocument(document) {
    try {
      await this.$refs.ref["showFile"]({
        documentId: this.taskId == 6 ? document.documentId : document._id,
        loanId: this.loanId,
        docName: this.taskId == 6 ? document.docName : document.name,
        docCategory: document.category,
        isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
      });
    } catch (error) {
      console.log(error);
    }
  }

  //CALL LOADER FUNCTION
  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
    this.sessionObject = this.$store.state.sessionObj;
  }

  //UPLOAD DOCUMENT
  public async uploadFile(event, document) {
    console.log("document", document);
    this.callLoader(true, null);
    document.isUploading = true;
    try {
      document.isUploading = true;
      const files = event.target.files;
      const formData: any = new FormData();
      this.docId = document._id;
      formData.append("loanId", this.loanId);

      if (this.taskId == 6) {
        formData.append("documentId", document.documentId);
      } else {
        formData.append("documentId", document._id);
      }

      formData.append("appraisalRefCount", this.appraisalCount);
      formData.append("secondAppraisalCount", null);
      if (document.borrowerUserId)
        formData.append("borrowerUserId", document.borrowerUserId);
      Array.from(files).forEach((f) => {
        formData.append("file", f);
      });
      const response = await Axios.post(
        BASE_API_URL + "loanChecklist/uploadDocument",
        formData,
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$snotify.success("Document has been uploaded");
        this.isProcessorNotifiedOnce = false;
        const doc = response.data.checklist.find((c) => c._id == document._id);
        document["docStatus"] = this.taskId == 6 ? doc.docStatus : doc.status;
        document["showEye"] = doc && doc.docFiles.length > 0 ? true : false;
        document["isUploading"] = false;
        document["isComplete"] = doc && doc.isComplete ? doc.isComplete : false;
        document["docLength"] = doc && doc.docFiles ? doc.docFiles.length : 0;
      }
      await this.$emit("refresh-task-list", true);
    } catch (error) {
      console.log(error);
      document["isUploading"] = false;
    }
    document.isUploading = false;
    await this.returnDocs();
    await this.isProcessorNotifiedForAllDocs();
    this.callLoader(false, null);
  }

  // CHECK WHETEHR PROCESSOR IS NOTIFIED OR NOT
  public async isProcessorNotifiedForAllDocs() {
    try {
      const res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isProcessorNotifiedForAllDocs/" +
          this.loanId +
          "/" +
          this.step +
          "?taskId=" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.alreadyNotified = res.data.alreadyNotified;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // NOTIFY PROCESSOR ABOUT UPLOAD.
  public async notifyProcessor() {
    try {
      if (this.isProcessorNotifiedOnce) return;

      const res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/notifyProcessorAboutUploadedDocs/" +
          this.loanId +
          "/" +
          this.step +
          "?taskId=" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201 || res.status == 202) {
        this.$snotify.success(res.data.message);
        this.cancel();
        await this.isProcessorNotifiedForAllDocs();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public cancel() {
    this.$modal.hide("addDocument");
    this.$modal.hide("notifyProcessor");
    this.document = {};
  }

  //delete a DOCUMENT FROM LIST
  public async deleteDoc(doc) {
    try {
      await this.$refs.refs["delConfirmationBox"]({
        msg: `Are you sure want to delete this Document ?`,
        data: {
          loanId: this.loanId,
          documentId: this.taskId == 6 ? doc.documentId : doc._id,
        },
        apiPath: "processor/deleteDoc",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async mounted() {
    if (this.$route.query.id) this.loanId = this.$route.query.id;
    this.reportsFor = this.$route.name;
    if (this.reportsFor == "low") this.taskId = 38;
    if (this.reportsFor == "order") this.taskId = 6;
    await this.getLoanDetails();
    await this.returnDocs();
    await this.isProcessorNotifiedForAllDocs();
  }
}
